import { Pane } from 'evergreen-ui'
import styled, { css, CSSProperties } from 'styled-components'
import { breakpoints } from '/fiweb/lib'

interface BorderedBoxProps {
  side?: 'bottom-right' | 'bottom-left' | 'top-right' | 'top-left'
  $bgColor?: CSSProperties['backgroundColor']
}

export const BorderedBox = styled(Pane)<BorderedBoxProps>`
  ${({ $bgColor, side, theme: { colors, spacing } }) => css`
    background-color: ${$bgColor || colors.fiBlue10};
    border-radius: 5px;
    padding: ${spacing.l};
    ${`border-${side ?? 'bottom-right'}-radius`}: ${spacing.s};

    @media ${(_) => breakpoints.small} {
      padding: ${spacing.s};
    }
  `}
`
