import * as React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { useCustomTheme } from '/fiweb/lib/theme'
import { useBreakpoint } from '/fiweb/lib'
import { Chevron, IconDirection } from '/fiweb/components/Icons'
import { InvestmentOfferRoute, NavigationMenuItem, NavigationSubmenuItem, NavigationSubmenuItemHash } from './types'
import { getDefaultSubmenuItem, getMenuItems, getSelectedMenuItem, getSelectedSubmenuItem } from './menuItems'
import { MobileTabs } from './MobileTabs'
import { MenuLink, NavigationBackground, NavigationContainer } from './StyledComponents'
import { usePublicInvestmentOfferFlags } from '../Context/PublicInvestmentOfferData'

export const NavigationTabs = () => {
  const { colors, spacing } = useCustomTheme()
  const { large } = useBreakpoint()
  const navigate = useNavigate()
  const location = useLocation()
  const { slug } = useParams<{ slug: string }>()
  const menuItems = getMenuItems()
  const intl = useIntl()
  const { isPreview, isReadonly } = usePublicInvestmentOfferFlags() // PREVIEW-HACK

  const [selectedItem, setSelectedItem] = React.useState<NavigationMenuItem>(null)
  const [selectedSubmenuItem, setSelectedSubmenuItem] = React.useState<NavigationSubmenuItem>(null)
  const [submenuItems, setSubmenuItems] = React.useState<NavigationSubmenuItem[]>(null)

  const navigateTo = (path: InvestmentOfferRoute, hash?: NavigationSubmenuItemHash) => {
    navigate({ pathname: path, hash })
  }

  // Navigate to clicked path, unless if allready there we toggle the submenu instead
  const menuItemClicked = (menuItem: NavigationMenuItem) => {
    const defaultSubmenuItem = getDefaultSubmenuItem(menuItem.key)

    if (!location.pathname.includes(`/investeringstilbud/${slug}/${menuItem.path}`)) {
      navigateTo(menuItem.path, defaultSubmenuItem?.hash)
    } else if (menuItem.submenu) {
      setSubmenuItems(submenuItems ? null : menuItem.submenu)
      const submenuItem = getSelectedSubmenuItem(menuItem.key, location.hash) || defaultSubmenuItem
      setSelectedSubmenuItem(selectedSubmenuItem ? null : submenuItem)
    }
  }

  React.useEffect(() => {
    const selected = getSelectedMenuItem(location.pathname)
    if (selected) {
      setSelectedItem(selected)

      if (selected.submenu) {
        setSubmenuItems(selected.submenu)

        if (location.hash) {
          const submenuItem = getSelectedSubmenuItem(selected.key, location.hash)
          setSelectedSubmenuItem(submenuItem || getDefaultSubmenuItem(selected.key))
        } else {
          const defaultSubmenuItem = getDefaultSubmenuItem(selected.key)
          setSelectedSubmenuItem(defaultSubmenuItem)
        }
      } else {
        setSubmenuItems(null)
        setSelectedSubmenuItem(null)
      }
    }
  }, [location])

  if (large) {
    return (
      <NavigationBackground>
        <MobileTabs navigate={navigateTo} selectedItem={selectedItem} selectedSubmenuItem={selectedSubmenuItem} />
      </NavigationBackground>
    )
  }

  return (
    <NavigationBackground>
      <NavigationContainer>
        {menuItems.map((item) => {
          const disabled = item.hideInReadonly && (isReadonly || isPreview)
          return (
            <MenuLink
              title={disabled ? intl.formatMessage({ id: 'notAvailable' }) : ''}
              $disabled={disabled}
              key={item.key}
              $selected={item.key === selectedItem?.key}
              onClick={() => !disabled && menuItemClicked(item)}
            >
              <FormattedMessage id={item.messageId} />
              {!!item.submenu?.length && (
                <Chevron
                  direction={submenuItems ? IconDirection.UP : IconDirection.DOWN}
                  height='12px'
                  width='12px'
                  style={{ marginLeft: spacing.xxs, color: colors.fiDark }}
                />
              )}
            </MenuLink>
          )
        })}
      </NavigationContainer>

      {!!submenuItems?.length && (
        <NavigationContainer>
          {submenuItems?.map((subItem) => (
            <MenuLink
              key={subItem.hash}
              $selected={subItem.hash === selectedSubmenuItem?.hash}
              onClick={() => navigateTo(selectedItem.path, subItem.hash)}
              $submenu
            >
              <FormattedMessage id={subItem.messageId} />
            </MenuLink>
          ))}
        </NavigationContainer>
      )}
    </NavigationBackground>
  )
}
