import * as React from 'react'
import { usePublicInvestmentOfferData } from './PublicInvestmentOfferData'
import { client } from '/~/lib/issuer-client/client'
import { PublicInvestmentOfferSummaryQuery, usePublicInvestmentOfferSummaryQuery } from '/~/types/issuer-graphql'

interface ContextValues {
  data?: PublicInvestmentOfferSummaryQuery
  loading: boolean
  refetch: () => void
}

const context = React.createContext<ContextValues>({
  data: undefined,
  loading: true,
} as ContextValues)

interface Props {
  slug: string
  children: React.ReactNode
  skip?: boolean // PREVIEW-HACK
}

export const Provider = ({ slug, children, skip }: Props) => {
  const res = usePublicInvestmentOfferSummaryQuery({
    client,
    variables: { slug },
    skip: !!skip, // PREVIEW-HACK
    fetchPolicy: 'cache-and-network',
  })

  return <context.Provider value={res}>{children}</context.Provider>
}

export const useReservedSubscriptionsSummary = () => {
  const { maxSharesToIssue, minSharesToIssue } = usePublicInvestmentOfferData()
  const { data, loading, refetch } = React.useContext(context)

  const summary = data?.publicInvestmentOfferSummary ?? {
    subscriptionsCount: 0,
    totalSharesReserved: 0,
    subscribersCount: 0,
  }

  const progress = {
    min: loading ? 0 : (summary.totalSharesReserved / minSharesToIssue) * 100,
    max: loading ? 0 : (summary.totalSharesReserved / maxSharesToIssue) * 100,
  }

  return {
    refetch: () => refetch(),
    summary,
    progress,
    loading,
  }
}
