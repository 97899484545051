import { PaneProps } from 'evergreen-ui'
import React from 'react'
import { StickerType } from '/fiweb/components/Sticker'

type Maybe<T> = T | null | undefined

export type File = {
  id?: Maybe<string>
  url?: Maybe<string>
  name?: Maybe<string>
  title?: Maybe<string>
  mimeType?: Maybe<string>
  originalName?: Maybe<string>
}

export type ReservationsSummary = {
  _id?: Maybe<string>
  count?: Maybe<number>
  shares?: Maybe<number>
  total?: Maybe<number>
  investors?: Maybe<number>
}

export interface CampaignCardViewData {
  _id: Maybe<string>
  title: Maybe<string>
  caption: Maybe<string>
  slug: Maybe<string>
  status: Maybe<string>
  recentViews: Maybe<number>
  raisingAmountMinimum: Maybe<number>
  raisingAmountMaximum: Maybe<number>
  raisingSharesMinimum: Maybe<number>
  raisingSharesMaximum: Maybe<number>
  startDate: Maybe<any>
  closeDate: Maybe<any>
  emailReminderCount: Maybe<number>
  recentReservationsCount: Maybe<number>
  hasVPS: Maybe<boolean>
  preAllocationGoalMet: Maybe<boolean>
  preAllocationPts: Maybe<number>
  companyLogoSmall?: Maybe<File>
  coverImage?: Maybe<File>
  reservationsSummary?: Maybe<ReservationsSummary>
}

export enum CardTypeEnum {
  ONGOING = 'ONGOING',
  FINISHED = 'FINISHED',
  COMING_SOON = 'COMING_SOON',
  UPCOMING = 'UPCOMING',
  DRAFT = 'DRAFT',
  ARCHIVED = 'ARCHIVED',
}

export interface CardDetailsType {
  imageUrl: string
  title: string
  caption: string
  clickNavigatesTo?: string
  stickerType?: StickerType
}

interface FollowDialogRenderFunctionProps {
  open?: boolean
  onClose: (didFollow: boolean) => void
  campaignId?: string
  emissionProcessId?: string
}

export type CampaignCardTemplateType = {
  cardType: CardTypeEnum
  imageUrl: string
  title: string
  caption: string
  clickNavigatesTo?: string
  stickerType?: StickerType
  raisingAmountMinimum?: number
  raisingAmountMaximum?: number
  reservedAmountInNok?: number
  reservedPercent?: number
  reservedPercentOfMax?: number
  visitorIsFollowing?: boolean
  followerCount?: number
  investorCount?: number
  timestamp?: number
  serverTimeOffset?: number
  websiteUrl?: string
  mobileView?: boolean
  slug: string
  disableFollowButton?: boolean
  campaignToolMode?: boolean
  followDialog?: (props: FollowDialogRenderFunctionProps) => React.ReactNode
  onClick?: () => void
  preorderButton?: React.ReactNode
  standardClickableAreaProps?: PaneProps
  reducedClickableAreaProps?: PaneProps
  cardDataFromOldApi?: boolean
} & (
  | {
      campaignId: string
      emissionProcessId?: never
    }
  | {
      emissionProcessId: string
      campaignId?: never
    }
  | {
      emissionProcessId?: never
      campaignId?: never
    }
)

export interface ClientCampaignCard {
  id: string
  title?: string
  imageUrl?: string
  caption?: string
  website?: string
  dates?: {
    publish?: Date
    open?: Date
    close?: Date
  }
  state: CardTypeEnum
  followerCount?: number
  fromNewApi: boolean
  recentSubscriptionsCount: number
  subscriptionsCount: number
  recentPageViewsCount: number
  finishedSuccessfully?: boolean
  slug?: string
  preorderSlug?: string
  finishedCampaignData?: {
    reservationsSummary?: ReservationsSummary
    raisingSharesMinimum?: number
    raisingSharesMaximum?: number
    raisingAmountMinimum?: number
    raisingAmountMaximum?: number
    status?: string
    slug?: string
  }
}
