import { NavigationMenuItem, NavigationMenuItemKey } from './types'

const allMenuItems: NavigationMenuItem[] = [
  {
    key: 'description',
    path: 'beskrivelse',
    messageId: 'investmentOfferPage.nav.description',
    default: true,
  },
  {
    key: 'emission',
    path: 'emisjon',
    messageId: 'investmentOfferPage.nav.emission',
    submenu: [
      {
        hash: 'oversikt',
        messageId: 'ioInfoEmissionClient.navigation.overview',
        default: true,
      },
      {
        hash: 'selskap',
        messageId: 'ioInfoEmissionClient.navigation.companyInfo',
      },
      {
        hash: 'finansiell-informasjon',
        messageId: 'ioInfoEmissionClient.navigation.financialInfo',
      },
      {
        hash: 'kapitalutvidelse',
        messageId: 'ioInfoEmissionClient.navigation.emission',
      },
    ],
  },
  {
    key: 'discussion',
    path: 'diskusjon',
    messageId: 'investmentOfferPage.nav.discussion',
    hideInReadonly: true,
  },
  {
    key: 'teamAndOwners',
    path: 'team-og-eiere',
    messageId: 'investmentOfferPage.nav.teamAndOwners',
  },
  {
    key: 'documents',
    path: 'dokumenter',
    messageId: 'investmentOfferPage.nav.documents',
  },
  {
    key: 'subscriptionList',
    path: 'tegningsliste',
    messageId: 'investmentOfferPage.nav.subscriptionList',
    hideInReadonly: true,
  },
]

export const getMenuItems = () => {
  return allMenuItems
}

export const getSelectedMenuItem = (pathname: string) => {
  return allMenuItems.find((item) => pathname.includes(item.path)) || allMenuItems.find((item) => item.default)
}

export const getDefaultSubmenuItem = (key: NavigationMenuItemKey) => {
  const menuItem = allMenuItems.find((item) => item.key === key)
  return menuItem.submenu?.find((item) => item.default) || null
}

export const getSelectedSubmenuItem = (key: NavigationMenuItemKey, hash: string) => {
  const menuItem = allMenuItems.find((item) => item.key === key)
  return menuItem.submenu.find((item) => hash.replace('#', '') === item.hash) || null
}
