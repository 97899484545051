import * as React from 'react'
import { Pane } from 'evergreen-ui'
import { Navigate, Route, Routes, useParams, Outlet } from 'react-router-dom'
import { Header } from './Header'
import Loading from '/~/components/common/Loading'
import { client } from '/~/lib/issuer-client/client'
import {
  ErrorCodeEnum,
  PublicInvestmentOfferBySlugQuery,
  usePublicInvestmentOfferBySlugQuery,
  useRegisterInvestmentOfferPageviewMutation,
} from '/~/types/issuer-graphql'
import { context as DataContext, usePublicInvestmentOfferFlags } from './Context/PublicInvestmentOfferData'
import { Provider as SummaryProvider } from './Context/ReservedSubscriptionsSummary'
import { Provider as ActionBarInViewProvider } from './Context/ActionBarInView'
import { NavigationTabs } from './NavigationTabs'
import { FloatingActionBar } from './ActionBar'
import { InvestmentOfferPageContainer } from './components'
import { LoginRequired } from './LoginRequired'
import { BackToTopProvider } from './Context/BackToTop'
import { Helmet } from '/~/components'
import dayjs from 'dayjs'
import { Page404 } from '/~/pages'

const InvestmentOfferPresentation = React.lazy(async () => ({
  default: (await import('./Presentation')).InvestmentOfferPresentation,
}))

const InvestmentOfferEmissionInfo = React.lazy(async () => ({
  default: (await import('./EmissionInfo')).InvestmentOfferEmissionInfo,
}))

const InvestmentOfferDiscussion = React.lazy(async () => ({
  default: (await import('./Discussion')).InvestmentOfferDiscussion,
}))

const InvestmentOfferTeamAndOwners = React.lazy(async () => ({
  default: (await import('./TeamAndOwners')).InvestmentOfferTeamAndOwners,
}))

const InvestmentOfferDocuments = React.lazy(async () => ({
  default: (await import('./Documents')).InvestmentOfferDocuments,
}))

const InvestmentOfferReservedSubscriptionsList = React.lazy(async () => ({
  default: (await import('./ReservedSubscriptionsList')).InvestmentOfferReservedSubscriptionsList,
}))

const InvestmentOfferReserveSubscription = React.lazy(async () => ({
  default: (await import('./ReserveSubscription')).InvestmentOfferReserveSubscription,
}))

interface InvestmentOfferPageContentProps {
  publicInvestmentOffer: PublicInvestmentOfferBySlugQuery['publicInvestmentOfferBySlug']
  publishedCampaignCard: PublicInvestmentOfferBySlugQuery['publishedCampaignCardByAlias']
}

const InvestmentOfferPageContent = ({
  publicInvestmentOffer,
  publishedCampaignCard,
}: InvestmentOfferPageContentProps) => {
  const { isOpen, userCanPreorder, isReadonly, isPreview, isValidating, loading } = usePublicInvestmentOfferFlags()

  const isOutdated = publicInvestmentOffer?.dates?.close
    ? !!dayjs().subtract(1, 'years').isAfter(dayjs(publicInvestmentOffer.dates.close))
    : false
  return (
    <>
      <Helmet
        title={`${publicInvestmentOffer?.title} investeringstilbud | Folkeinvest `}
        description={publishedCampaignCard?.caption}
        url={`${window.location.origin}/kampanje/${publicInvestmentOffer?.slug}`}
        imageUrl={publicInvestmentOffer?.presentation?.coverImage?.cropped?.url}
        hide={isOutdated}
      />

      <BackToTopProvider>
        {/* PREVIEW-HACK */}
        <SummaryProvider slug={publicInvestmentOffer?.slug} skip={isReadonly || isPreview}>
          <Routes>
            {(isOpen || userCanPreorder || isValidating) && (
              <Route path='tegning/*' Component={InvestmentOfferReserveSubscription} />
            )}
            <Route
              element={
                <Pane display='flex' flexDirection='column' alignItems='center'>
                  <ActionBarInViewProvider>
                    <Header />
                    <NavigationTabs />
                    <InvestmentOfferPageContainer>
                      {loading ? (
                        <Loading />
                      ) : (
                        <React.Suspense fallback={<Loading />}>
                          <Outlet />
                        </React.Suspense>
                      )}
                    </InvestmentOfferPageContainer>
                    <FloatingActionBar />
                  </ActionBarInViewProvider>
                </Pane>
              }
            >
              <Route
                path='diskusjon'
                element={
                  // PREVIEW-HACK
                  isReadonly || isPreview ? null : <LoginRequired children={<InvestmentOfferDiscussion />} />
                }
              />
              <Route
                path='tegningsliste'
                element={
                  // PREVIEW-HACK
                  isReadonly || isPreview ? null : (
                    <LoginRequired children={<InvestmentOfferReservedSubscriptionsList />} />
                  )
                }
              />
              <Route path='beskrivelse' Component={InvestmentOfferPresentation} />
              <Route path='emisjon/*' element={<LoginRequired children={<InvestmentOfferEmissionInfo />} />} />
              <Route path='team-og-eiere' element={<LoginRequired children={<InvestmentOfferTeamAndOwners />} />} />
              <Route path='dokumenter' element={<LoginRequired children={<InvestmentOfferDocuments />} />} />
              <Route path='/' Component={InvestmentOfferPresentation} />
              <Route path='live' element={<Navigate to={`/sanntid/${publicInvestmentOffer?.slug}`} replace />} />
              <Route path='/*' element={<Navigate to='./' replace />} />
            </Route>
          </Routes>
        </SummaryProvider>
      </BackToTopProvider>
    </>
  )
}

export const InvestmentOfferPage = () => {
  const { slug } = useParams<{ slug: string }>()

  const [registerPageview] = useRegisterInvestmentOfferPageviewMutation({ client })

  React.useEffect(() => {
    if (!slug.startsWith('_preview_')) {
      registerPageview({ variables: { slug } })
    }
  }, [])

  const {
    data: queryData,
    previousData,
    loading,
    error,
  } = usePublicInvestmentOfferBySlugQuery({
    variables: { slug, isPreview: slug.startsWith('_preview_') },
    client,
    fetchPolicy: 'cache-and-network',
  })

  const data = queryData || previousData ? { ...previousData, ...queryData } : null

  if (!loading && (error || !data)) {
    return <Page404 archived={!!error?.graphQLErrors?.find((err) => err.extensions.code === ErrorCodeEnum.ARCHIVED)} />
  }

  return (
    <DataContext.Provider value={{ loading, ...data }}>
      <InvestmentOfferPageContent
        publicInvestmentOffer={data?.publicInvestmentOfferBySlug}
        publishedCampaignCard={data?.publishedCampaignCardByAlias}
      />
    </DataContext.Provider>
  )
}
