import * as React from 'react'
import { Pane, LockIcon } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import dayjs from 'dayjs'
import { Divider } from '/fiweb/components'
import { DisplayTime } from '/fiweb/components'
import StatisticSection from './StatisticSection'
import { CardDataType } from './CardData'

type Props = { finished?: boolean } & Pick<
  CardDataType,
  'investorCount' | 'reservedPercent' | 'reservedPercentOfMax' | 'timestamp' | 'mobileView' | 'serverTimeOffset'
>

export const DataSection = ({
  investorCount = 0,
  reservedPercent = 0,
  reservedPercentOfMax = 0,
  timestamp = 0,
  mobileView,
  finished,
  serverTimeOffset = 0,
}: Props) => {
  const theme = useCustomTheme()
  const success = reservedPercent >= 100
  const lessThan24hours = dayjs(timestamp).isBefore(dayjs(Date.now() + (serverTimeOffset ?? 0)).add(1, 'day'))

  return (
    <Pane
      display='flex'
      flexFlow='row nowrap'
      justifyContent='space-between'
      alignItems='center'
      marginTop={theme.spacing.xs}
      marginBottom={theme.spacing.xs}
    >
      <StatisticSection
        mobileView={mobileView}
        label={success ? 'Fulltegnet' : 'Minstemål'}
        value={`${Math.floor(success ? reservedPercentOfMax : reservedPercent)}%`}
      />
      <Divider vertical height={mobileView ? 36 : 44} marginLeft={6} marginRight={6} />
      <StatisticSection mobileView={mobileView} label='Investorer' value={investorCount} />
      <Divider vertical height={mobileView ? 36 : 44} marginLeft={6} marginRight={6} />

      {finished ? (
        <StatisticSection
          mobileView={mobileView}
          label='Avsluttet'
          alignValue='center'
          value={<LockIcon color='muted' />}
        />
      ) : (
        <StatisticSection
          mobileView={mobileView}
          label='Lukkes om'
          textColor={lessThan24hours ? 'danger' : undefined}
          value={<DisplayTime date={timestamp} />}
        />
      )}
    </Pane>
  )
}
