import * as React from 'react'
import { Button, Pane, PersonIcon, Spinner, toaster } from 'evergreen-ui'
import { useAuthContext } from '/~/utils/AuthContext'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { breakpoints } from '/fiweb/lib'
import styled, { css } from 'styled-components'

interface Props {
  onClose?: () => void
}

const StyledPane = styled(Pane)`
  ${({ theme }) => css`
    display: flex;
    min-width: 250px;
    gap: ${theme.spacing.xs};
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    
    @media ${breakpoints.large} {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  
`}`

const StyledAuthButton = styled(Button).attrs({ appearance: 'text', size: 'small' })`
  ${({ theme }) => css`
    min-width: 150px;
    border: 1px solid ${theme.colors.fiDark70};
    &:not([disabled]):hover {
      color: ${theme.colors.fiDark};
      border-color: ${theme.colors.fiDark};
    }
    @media ${breakpoints.large} {
      width: 100%;
      max-width: 250px;
    }
`}`

export const NavBarAuth = ({ onClose }: Props) => {
  const { user, loading, logOut } = useAuthContext()
  const location = useLocation()

  const handleLogout = async () => {
    onClose?.()
    await logOut()
    toaster.success('Du er nå logget ut')
  }

  return (
    <StyledPane>
      <Button
        size='small'
        appearance='text'
        onClick={user ? handleLogout : () => onClose?.()}
        is={user ? undefined : RouterLink}
        to={
          user
            ? undefined
            : {
                pathname: '/innlogging',
                search: location.pathname === '/' ? undefined : `redirectToAfterAuth=${location.pathname}`,
              }
        }
      >
        {user ? 'Logg ut' : 'Logg inn'}
      </Button>
      <StyledAuthButton
        iconBefore={user ? PersonIcon : null}
        is={RouterLink}
        onClick={() => onClose?.()}
        to={user ? '/konto' : '/innlogging/registrering'}
      >
        {loading ? <Spinner size={16} /> : user ? 'Min Profil' : 'Lag Profil'}
      </StyledAuthButton>
    </StyledPane>
  )
}
