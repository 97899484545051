import { Pane } from 'evergreen-ui'
import * as React from 'react'
import NavLogo from './NavLogo'
import { useBreakpoint } from '/fiweb/lib'
import { useCustomTheme } from '/fiweb/lib/theme'
import { navItems } from './navItems'
import { Link as RouterLink } from 'react-router-dom'
import { NavBarItem } from './NavBarItem'
import { useWindowScrollPosition } from 'rooks'
import { NavBarAuth } from './NavBarAuth'
import { NavBarMobileMenu } from './NavBarMobileMenu'
import { useIsSpecialFrontPageEnabledQuery } from '/~/types/graphql'

export const NavBar = () => {
  const { data } = useIsSpecialFrontPageEnabledQuery()
  const { colors, spacing } = useCustomTheme()
  const { medium, large } = useBreakpoint()
  const position = useWindowScrollPosition()
  const useBlendedBackground = data?.isSpecialFrontPageEnabled && location.pathname === '/'
  const isScrolled = position.scrollY > 1

  return (
    <Pane
      display='flex'
      position='sticky'
      width='100%'
      height={80}
      top={0}
      zIndex={10}
      justifyContent='center'
      overflow='hidden'
      background={!isScrolled && useBlendedBackground ? 'transparent' : colors.white}
      transition='0.4s'
      boxShadow={isScrolled ? '0px 9px 10px -8px rgba(45, 51, 64, 0.18)' : 'inherit'}
      paddingX={spacing.s}
    >
      <Pane
        width='100%'
        maxWidth={1260}
        display='flex'
        justifyContent='space-between'
        height='100%'
        alignItems='center'
      >
        <Pane is={RouterLink} width={medium ? 30 : 'auto'} overflow='hidden' to='/'>
          <NavLogo small={medium} />
        </Pane>

        {large ? (
          <NavBarMobileMenu />
        ) : (
          <Pane display='flex' columnGap={spacing.s}>
            <Pane display='flex' columnGap={spacing.xs}>
              {navItems.map((navItem) => (
                <NavBarItem key={navItem.key} {...navItem} />
              ))}
            </Pane>
            <NavBarAuth />
          </Pane>
        )}
      </Pane>
    </Pane>
  )
}
