import { Pane, Button, ArrowTopRightIcon } from 'evergreen-ui'
import React, { useRef, useState, useEffect } from 'react'
import { Player, PlayerEvent } from '@lottiefiles/react-lottie-player'
import { useBreakpoint } from '/fiweb/lib'
import styled, { keyframes } from 'styled-components'
import { useIsSpecialFrontPageEnabledQuery } from '/~/types/graphql'
import { useLocation } from 'react-router-dom'
import { useCustomTheme } from '/fiweb/lib/theme'

const frame = {
  waveFrame: '/wave-frame.json',
  waveFrameMobile: '/wave-frame-mobile.json',
}

const bgImage = '/images/water.jpg'
const target = frame.waveFrame
const mobileTarget = frame.waveFrameMobile
const noBorder = true

const HeroBannerWrapper = styled(Pane)`

`

export const FlexText = ({ content, size, weight }: { content: string; size: number; weight?: number }) => {
  const words = content.split(' ')

  return (
    <>
      {words.map((word, i) => {
        return (
          <div
            key={`${word}_${i}`}
            style={{ fontSize: size, fontWeight: weight, flexShrink: 1 }}
            dangerouslySetInnerHTML={{ __html: word }}
          />
        )
      })}
    </>
  )
}

export const HeroBanner = () => {
  const { data } = useIsSpecialFrontPageEnabledQuery()
  const { medium, large } = useBreakpoint()
  const { spacing, colors } = useCustomTheme()
  const location = useLocation()
  const [borderRemoved, setBorderRemoved] = React.useState(false)
  const enabled = !!data?.isSpecialFrontPageEnabled

  const bannerRef = useRef(null)
  const [isInView, setIsInView] = useState(false)

  const checkInView = () => {
    const rect = bannerRef.current.getBoundingClientRect()
    setIsInView(rect.top < window.innerHeight && rect.bottom >= 0)
  }

  useEffect(() => {
    checkInView()
  }, [])

  useEffect(() => {
    document.addEventListener('scroll', checkInView)
    return () => {
      document.removeEventListener('scroll', checkInView)
    }
  }, [])

  const heroBannerBG = {
    background: bgImage
      ? `url(${bgImage})`
      : frame.waveFrame
        ? 'linear-gradient(to right, #FFF 1px, #E4EDE1 2px, #9FCBE6)'
        : undefined,
    backgroundSize: 'cover',
  }

  const fadeFromleft = keyframes`
      0% {
        transform: translateX(-100px);
        opacity: 0;
      }
      40% {
        transform: translateX(50px);
        opacity: 0.8;
      }
      75%{
        transform: translateX(-20px);
        opacity: 1;
      }
      100%{
        transform: unset;
        opacity: 1;
      }
    `
  const fadeFromTop = keyframes`
    0% {
      transform: translateY(-100px);
      opacity: 0;
    }
    40% {
      transform: translateY(50px);
      opacity: 0.8;
    }
    75%{
      transform: translateY(-20px);
      opacity: 1;
    }
    100%{
      transform: unset;
      opacity: 1;
    }
  `
  const animationDelay = 0.2

  const HeroBannerContainer = styled(Pane)`

    .enter{
      opacity:0;
      animation-name: ${medium ? fadeFromTop : fadeFromleft};
      animation-iteration-count: 1;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
      animation-duration: 2s;
    }
    .second{
      animation-delay: ${(animationDelay * 0.2).toString()}s;
    }
    .third{
      animation-delay: ${(animationDelay * 0.4).toString()}s;
    }
    .fourth{
      animation-delay: ${(animationDelay * 0.6).toString()}s;
    }
    .fifth{
      animation-delay: ${(animationDelay * 0.8).toString()}s;
    }
  `

  const TextPane = styled(Pane)`
    display: flex;
    flex-wrap: wrap;
    justify-content: ${medium ? 'flex-start' : 'flex-end'};

    >div{
      padding-top: 1px;
      padding-bottom: 1px;
      padding-left: 4.5px;
      padding-right: 4.5px;
      user-select: none;

      &:first-child{
        padding-left: 6px;
      }
      &:last-child{
        padding-right: 6px;
      }
    }

    &.white{
      color: ${colors.white};
      >div{
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
    
    &.black{
      color: ${colors.fiText};
      >div{
        background-color: white;
      }
    }
`
  /*   const ImagePane = styled(Pane)`
    padding: ${spacing.xxs};
    background-color:rgba(255, 255, 255, 1);
    border-radius: 5px;

    >img{
      width: 175px;
    }

    @media ${breakpoints.medium} {
      >img{
        width:100%;
        max-width: 250px;
      }
    }
` */

  const removeBorder = () => {
    try {
      // Removes an problematic styling that causes a faint border to appear
      const svgElement = document.getElementById('lottie').childNodes[0] as SVGElement
      svgElement.style.transform = 'unset'
      setBorderRemoved(true)
    } catch {}
  }

  if (!enabled || location?.pathname !== '/') {
    return null
  }

  const playerProps = {
    onEvent: (e: PlayerEvent) => e === 'load' && removeBorder(),
    autoplay: true,
    loop: true,
    speed: 0.5,
    style: medium ? { width: '100vw', height: 800, marginBottom: '-5px' } : { width: 800, height: 800 },
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <HeroBannerWrapper
      ref={bannerRef}
      position='relative'
      display='flex'
      justifyContent='center'
      flexGrow={1}
      top={medium ? undefined : -80}
      marginBottom={medium ? undefined : -80}
      minWidth={medium ? '100vw' : undefined}
      minHeight={medium && noBorder ? '100vh' : 800}
      overflow='hidden'
      style={heroBannerBG}
    >
      <Pane display='flex' position='relative' width='100%'>
        <Pane position='absolute' display='flex' justifyContent='center' width='100%' height='100%' maxWidth='800px'>
          <HeroBannerContainer
            width='100%'
            height='100%'
            maxWidth={medium ? '350px' : '450px'}
            display='flex'
            gap={spacing.xs}
            justifyContent='center'
            alignItems='flex-end'
            flexDirection='column'
          >
            <TextPane className={isInView ? 'black enter' : undefined}>
              <FlexText content={'<b>Nye</b> finansielle investerings&shy;produkter.'} size={large ? 30 : 40} />
            </TextPane>
            <TextPane className={isInView ? 'black enter second' : undefined}>
              <FlexText content={'Folkeinvest Formidling'} size={large ? 30 : 32} weight={600} />
              <FlexText content={'lanserer første lånetilbud.'} size={large ? 30 : 32} weight={300} />
            </TextPane>

            <Pane
              display='flex'
              paddingTop={spacing.xs}
              alignItems='flex-end'
              justifyContent={medium ? 'center' : 'flex-end'}
              className={isInView ? 'enter third' : undefined}
              width='100%'
            >
              <Button
                aria-label='Les mer på Folkeinvest Formidling'
                size={medium ? 'medium' : large ? 'small' : 'medium'}
                appearance='invertedPrimary'
                is='a'
                href={'http://www.folkeinvestformidling.no'}
                target='_blank'
                iconAfter={ArrowTopRightIcon}
                boxShadow='10px'
              >
                Les mer
              </Button>
            </Pane>
          </HeroBannerContainer>
        </Pane>
        {noBorder ? undefined : <Player {...playerProps} src={medium ? mobileTarget : target} renderer='svg' />}
      </Pane>
      <Pane visibility={borderRemoved ? 'visible' : 'hidden'} height='100%' flexGrow={2} />
    </HeroBannerWrapper>
  )
}
